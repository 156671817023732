/*!sc*/
*,
*:before,
*:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*!sc*/
p,
h1,
h2,
h4,
h5,
h6,
blockquote,
pre,
ul,
ol,
li,
table,
tr,
th,
td,
input,
textarea,
span,
code,
.selectable {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

/*!sc*/
*:focus {
  outline: none;
}

/*!sc*/
textarea:focus,
input:focus {
  outline: none;
}

/*!sc*/
html {
  -webkit-scroll-behavior: smooth;
  -moz-scroll-behavior: smooth;
  -ms-scroll-behavior: smooth;
  scroll-behavior: smooth;
  font-size: 100%;
}

/*!sc*/
html,
body,
* {
  font-family: "__gilroy_2ec981", "__gilroy_Fallback_2ec981", -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  line-height: 2;
}
