.mktoForm .mktoButtonWrap.mktoSimple .mktoButton {
  color: #fff;
  border: 1px solid #75ae4c;
  padding: 0.4em 1em;
  font-size: 1em;
  background-color: #99c47c;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#99c47c),
    to(#75ae4c)
  );
  background-image: -webkit-linear-gradient(top, #99c47c, #75ae4c);
  background-image: -moz-linear-gradient(top, #99c47c, #75ae4c);
  background-image: linear-gradient(to bottom, #99c47c, #75ae4c);
}
.mktoForm .mktoButtonWrap.mktoSimple .mktoButton:hover {
  border: 1px solid #447f19;
}
.mktoForm .mktoButtonWrap.mktoSimple .mktoButton:focus {
  outline: none;
  border: 1px solid #447f19;
}
.mktoForm .mktoButtonWrap.mktoSimple .mktoButton:active {
  background-color: #75ae4c;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#75ae4c),
    to(#99c47c)
  );
  background-image: -webkit-linear-gradient(top, #75ae4c, #99c47c);
  background-image: -moz-linear-gradient(top, #75ae4c, #99c47c);
  background-image: linear-gradient(to bottom, #75ae4c, #99c47c);
}
/* Main css */
p {
  color: rgb(71, 103, 136);
}
h3 {
  color: #071e55 !important;
  font-family: Roboto, sans-serif;
  font-weight: 600 !important;
  line-height: 1.2 !important;
  margin-bottom: 20px !important;
  margin-top: 0 !important;
}
.main-color {
  color: rgb(0, 107, 255);
}
.container-main {
  padding: 3rem 6%;
}
.bg-main {
  background-color: #fafdff;
}
.Getdemo {
  width: 100%;
  margin-bottom: 32px;
  text-align: left;
  color: #006bff;
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 600;
  -webkit-letter-spacing: 1.25px;
  -moz-letter-spacing: 1.25px;
  -ms-letter-spacing: 1.25px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}
.scheduling {
  font-size: 2.5rem;
  line-height: 1.2;
  margin-bottom: 24px;
  white-space: pre-line;
  text-align: left;
  color: rgb(11, 53, 88);
  font-weight: 700;
}
ul li span {
  color: rgb(71, 103, 136);
}
.input-icon {
  position: relative;
}
.input-icon i {
  position: absolute;
  top: 40%;
  left: 3%;
  transform: translateY(-50%);
  color: #999; /* Adjust icon color */
}
input.register-input,
textarea.register-input {
  width: 100% !important;
  height: 52px !important;
  padding-left: 11% !important;
  padding-right: 5% !important;
  margin-bottom: 15px !important;
  color: #555 !important;
  border: 1px solid rgb(166, 187, 209);
  background-color: #f9f9f9 !important;
  font-size: 16px !important;
  border-radius: 5px 5px 5px 5px !important;
}
.select__control {
  border: 1px solid rgb(166, 187, 209) !important;
}
input:focus,
.select__control:focus {
  border: 1px solid #1089d4 !important;
}
ul li {
  list-style: none !important;
}
/* input:focus-visible,
.select__control:focus {
  border: 1px solid #1089d4 !important;
} */
input.register-submit,
#modalSubmit {
  background: #1089d4;
  border: none;
  color: #fff;
  letter-spacing: 1px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  width: 100%;
  max-width: 100%;
  padding: 16px 0;
  text-transform: uppercase;
  -webkit-border-radius: 3px 3px;
  -moz-border-radius: 3px 3px;
  border-radius: 3px 3px;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}
.select__input-container {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.btn-blue {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  color: #fff;
  background-color: #1089d4;
  border: 1px solid #1089d4;
  box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.27);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
  letter-spacing: 1px;
  border-radius: 5px 5px;
  margin: 15px 10px 0 0;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
  display: inline-block;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}
.selected-timeSlot {
  background-color: #1089d4;
  color: #fff;
}
.time-slots {
  display: flex;
  flex-direction: column;
}

.time-slot {
  cursor: pointer;
  padding: 5px;
}
.MuiPickersLayout-actionBar {
  display: none !important;
}
.btn-cancle {
  color: #fff;
  background-color: #c13333 !important;
  border: none !important;
}

/* Modal */
.scrollModal {
  height: 40vh !important;
  z-index: 99999999 !important;
  --bs-modal-width: 45vw !important;
}
#modalToggle {
  position: absolute;
  background-color: #ffff;
  border-radius: 50%;
  right: -15px !important;
  top: -15px !important;
}
#modalToggle i {
  font-size: 15px !important;
}
.spin-hourglass {
  animation: spinhourglass 2s infinite linear;
}

@keyframes spinhourglass {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.createMeeting .modal-title {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  text-transform: none;
  position: relative;
  display: block;
  padding: 13px 16px;
  padding-bottom: 13px;
  font-size: 27px;
  line-height: normal;
  text-align: center;
}
.text-blue {
  color: rgb(16, 137, 212) !important;
}
.swal2-confirm.swal2-styled {
  background-color: #1089d4;
  right: 0;

  position: absolute;
  margin: 2rem;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.swal2-confirm:focus {
  box-shadow: none !important;
}
.swal2-actions {
  padding: 1rem;
}
